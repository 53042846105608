import React from 'react';
import config from '../../config';

export default function Main() {
  return (
    <section id="main" className="main-section text-center">
      <div className="container d-flex h-100 align-items-center">
        <div className="mx-auto text-center">
          <h1 className="mx-auto my-0 text-uppercase">{config.heading}</h1>
          <h2 className="text-white-50 mx-auto mt-2 mb-5">{config.subHeading}</h2>
          <a href={config.socialLinks['yelp']['url']} className="btn btn-primary">Book Now</a>
        </div>
      </div>
    </section>
  )
}