import React from 'react';
import marita from '../assets/images/marita.png';

export default function AboutSection() {
  return (
    <section id="about" className="tot-about-section text-left">
      <div className="container">
        <div className="row">
          <div id="tot-about-image" className="col-lg-4">
            <img src={marita} className="img-fluid" alt="" />
          </div>
          <div id="about-content" className="col-lg-8 mx-auto">
            <p className="text-white-50 h5">
              Touch of Tranquility, LLC. is a private massage studio established in 2000 by Marita, 
              a certified and licensed massage therapist. Located in Port Republic, MD, she provides
              the best care and service to her clients by taking continuing education courses on a regular basis. 
            </p>
            <br/>
            <p className="text-white-50 h5">
              Massage is Marita's PASSION! She is reliable, consistent, professional yet personable. 
              She LOVES working with Calvert County’s hard-working men and women who NEED and 
              VALUE relaxation and being pampered! 
            </p>
            <br/>
            <p className="text-white-50 h5">
              It is her goal to provide each individual the best possible massage, created just for them.
              Everyone is different and therefore, she takes the time to truly listen to
              your likes and dislikes to provide the most personalized, comfortable, and effective massage.
              She makes sure to discuss areas of concern including tolerance, level of pressure, and more. 
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}