import React from 'react';
import { Carousel } from 'react-bootstrap';
import angel from '../assets/images/angel.jpg';
import sign from '../assets/images/sign.jpg';
import water from '../assets/images/carousel/water.jpg';
import eagle1 from '../assets/images/carousel/eagle1.jpg';
import eagle2 from '../assets/images/carousel/eagle2.jpg';
import studio1 from '../assets/images/carousel/studio1.jpg';
import studio3 from '../assets/images/carousel/studio3.jpg';
import studio8 from '../assets/images/carousel/studio8.jpg';

export default function ServicesSection() {
  const carouselImages = [water, eagle2, studio1, studio3, studio8, eagle1];

  return (
    <section id="services" className="services-section bg-light">
      <div className="container">  
        <div className="row align-items-center no-gutters mb-4 mb-lg-5">
          <div id="tot.carousel" className="col-xl-8 col-lg-7">
            <Carousel interval={3000}>
              {
                carouselImages.map(source => {
                  return (
                    <Carousel.Item>
                      <img src={source} alt='' className="d-block w-100" />
                    </Carousel.Item>
                  )
                })
              }
            </Carousel>
          </div>
          <div className="col-xl-4 col-lg-5">
            <div className="featured-text text-center text-lg-left">
              <h4>Swedish</h4>
              <p>
                <i>superficial touch for relaxation</i><br/>
                <strong>60 min - $100 | 80 min - $130</strong>
              </p>
              <h4>Deep Tissue</h4>
              <p>
                <i>deeper touch into muscle and pressure points, releasing
                knots and stress</i><br/>
                <strong>60 min - $100 | 80 min - $130</strong>
              </p>
              <h4>Pregnancy Massage</h4>
              <p>
                <i>side-lying position, cradling pillows around neck and
                legs for best comfort</i><br/>
                <strong>60 min - $145</strong>
              </p>
              <h4>Amenities</h4>
              <p>
                Heated Table<br/>
                Hot Towels & Stones<br/>
                Eye Mask<br/>
                Complimentary Water
              </p>
            </div>
          </div>
        </div>

        <div className="row justify-content-center no-gutters mb-5 mb-lg-0">
          <div className="col-lg-6">
            <img src={sign} alt='' className="d-block w-100" />
          </div>
          <div className="col-lg-6">
            <div className="bg-black text-center h-100 service">
              <div className="d-flex h-100">
                <div className="service-text w-100 my-auto text-center text-lg-left">
                  <p className="text-white-50 mb-0">
                    ~ Experience Massages in a Quiet, Relaxed and Totally Private Atmosphere.
                    The in-home massage studio is tucked away in a secluded and quaint place
                    away from the hustle and bustle. It's located on the Chesapeake Bay with
                    BEAUTIFUL VIEWS and brings a sense of calm and relaxation. A private walkway
                    guides you through a separate entrance to your welcoming and cozy massage room. If preferred, 
                    the massage table can be heated. The use of heated bags and towels adds yet
                    another dimension of relaxation.
                  </p>
                  <br/>
                  <p className="text-white-50 mb-0">
                    ~ On top of getting the "kinks" out, Marita will provide a one-on-one consultation
                    to tailor a massage just for YOU! She will help you accomplish <u>YOUR</u> total 
                    relaxation goals and achieve the health benefits massage can provide by relieving 
                    stress from your neck, back, face, scalp, arms, legs, feet and hands, releasing and
                    reducing your overall muscle tension or fatigue, and even relieving headaches or migraines.
                    This will BOOST the immune system as well as IMPROVE circulation and energy flow which
                    then allows your body to rid itself of unwanted wastes and toxins.
                  </p>
                  <hr className="d-none d-lg-block mb-0 ml-0" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center no-gutters">
          <div className="col-lg-6">
            <img src={angel} alt='' className="d-block w-100" />
          </div>
          <div className="col-lg-6 order-lg-first">
            <div className="bg-black text-center h-100 service">
              <div className="d-flex h-100">
                <div className="service-text w-100 my-auto text-center text-lg-right">
                  <h4 className="text-white-50">YOU DESERVE A BREAK!</h4>
                  <p className="text-white-50">CALL FOR AN APPOINTMENT TODAY<br/>AND FEEL THE DIFFERENCE!</p>
                  <p className="text-white-50">
                    By Appointment ONLY | Monday - Friday 9a - 5p
                    <br/>
                    Alternating Saturdays 10a - 2p
                    <br/>
                    Additional days and times possible (upon request)
                    <br/>
                    Please download and fill out this&nbsp;
                    <a href="src/files/in-take_form.pdf" download>
                      In-take Form
                    </a>
                  </p>
                  <hr className="d-none d-lg-block mb-0 mr-0" />
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
  )
}