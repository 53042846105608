import React from 'react';

import Layout from '../components/Layout';
import StickyHeader from '../components/StickyHeader';
import StickySocialLinks from '../components/StickySocialLinks';
import Main from '../components/Main';
import AboutSection from '../components/AboutSection';
import ServicesSection from '../components/ServicesSection';
import ContactSection from '../components/ContactSection';
import Footer from '../components/Footer';

const IndexPage = () => (
  <Layout>
    <StickyHeader /> {/* Sticky horizontal menu */}
    <StickySocialLinks /> {/* Sticky vertical list of social links */}
    <Main />
    <AboutSection />
    <ServicesSection />
    <ContactSection />
    <Footer />
  </Layout>
);

export default IndexPage;
