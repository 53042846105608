import React from 'react';
import config from '../../config';
import { Popover, OverlayTrigger } from 'react-bootstrap';

export default function StickySocialLinks() {
  let buttons = Object.keys(config.socialLinks).map(socialSite => {
    const { icon, url, background } = config.socialLinks[socialSite];
    return (
      <a key={url} href={url} className="mx-2" style={{backgroundColor: background}}>
        <i className={`fab ${icon}`}></i>
      </a>
    );
  })

  let covidPopover = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">Covid Precautions</Popover.Title>
      <Popover.Content>
        <div>
          <p>
            <i className="fas fa-bed"></i>
            &nbsp; Freshly washed towels and linens (this is standard but worth reiterating) 
          </p>
          <p>
            <i className="fas fa-head-side-mask"></i>
            &nbsp; Mask worn at all times
          </p>
          <p>
            <i className="fas fa-soap"></i>
            &nbsp; Cleaning and disinfection of studio before and after EVERY session 
          </p>
          <p>
            <i className="fas fa-hands-wash"></i>
            &nbsp; Consistent and thorough hand washing 
          </p>
          <p>
            <i className="fas fa-pump-soap"></i>
            &nbsp;&nbsp; Hand sanitizer available 
          </p>
        </div>
      </Popover.Content>
    </Popover>
  );

  buttons.push(
    (
      <OverlayTrigger trigger="click" placement="left" overlay={covidPopover}>
        <a className="mx-2" style={{backgroundColor: "#F0E68C"}}>
          <i className="fas fa-head-side-mask"></i>
        </a>
      </OverlayTrigger>
    )
  );

  return (
    <div>
      <div className="social d-flex btn-group-vertical">{buttons}</div>
    </div>
  );
}
